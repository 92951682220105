import React from 'react';
import '../css/projects.scss';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import HeadingDecorated from '../components/HeadingDecorated';
import Project from '../components/Project';
import Seo from '../components/Seo';

export const query = graphql`
  {
    projects: allStrapiProject(sort: { fields: position, order: ASC }) {
      nodes {
        id
        avatar {
          localFile {
            url
          }
        }
        name
        summary {
          data {
            summary
          }
        }
      }
    }
  }
`;

const ProjectsPage = ({ data: { projects } }) => {
  return (
    <Layout>
      <Seo title="Nasze projekty" />
      <div className="projects">
        <section>
          <HeadingDecorated className="projects__section-heading">
            Nasze projekty
          </HeadingDecorated>
          <div className="projects__section-content">
            {projects.nodes.map((item) => (
              <Project data={item} key={item.id} />
            ))}
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default ProjectsPage;
