import React from 'react';
import { Link } from 'gatsby';
import Markdown from './Markdown';

const Project = ({ data }) => (
  <article className="projects__project">
    <img
      src={data.avatar.localFile.url}
      className="accent-shadow projects__avatar"
      alt={data.name}
    />
    <h3 className="projects__name">{data.name}</h3>
    <Markdown className="projects__description">
      {data.summary.data.summary}
    </Markdown>
    <Link to={`/nasze-projekty/${data.id}`} className="projects__more-link">
      Więcej o projekcie
    </Link>
  </article>
);

export default Project;
